
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

/* Include Tailwind’s base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply bg-primary text-white font-semibold py-2 px-4 rounded-lg transition duration-200;
  }
  .btn:hover {
    @apply bg-secondary;
  }
  .btn-primary {
    @apply bg-primary text-white;
  }
  .btn-secondary {
    @apply bg-secondary text-white;
  }

  /* Inputs */
  .input {
    @apply border border-gray-300 p-3 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none;
  }
  .input:focus {
    @apply border-primary;
  }

  /* Forms */
  .label {
    @apply text-sm font-semibold text-body mb-2;
  }
  .select {
    @apply border border-gray-300 p-3 rounded-lg focus:ring-2 focus:ring-primary focus:outline-none;
  }

  /* Links */
  .link {
    @apply text-primary underline transition duration-200;
  }
  .link:hover {
    @apply text-secondary;
  }

  /* Card / Container */
  .card {
    @apply bg-white p-6 shadow-lg rounded-lg;
  }

  /* Typography */
  .heading {
    @apply text-3xl font-bold text-primary mb-4;
  }
  .paragraph {
    @apply text-body text-base mb-4;
  }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fill-rect {
  animation: fill 4s ease-in-out forwards;
}